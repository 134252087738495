@import "https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&display=swap";
@import "./shared/colors.scss";

html {
  height: 100%;

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.17em;
  }

  h3 {
    font-size: 1.12em;
  }

  h4 {
    font-size: .83em;
  }

  h5 {
    font-size: .75em;
  }

  h6 {
    font-size: .5em;
  }
}

#root {
  min-height: calc(100% - 7.5rem);
  padding-top: 4rem;
  padding-bottom: 3.5rem;
  position: relative;
}

body {
  background-color: $background-color;
  height: 100%;
  color: $font-color;

  * {
    font-family: "Oxanium", sans-serif;
  }
}

main {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 60rem;
  margin: auto;
  padding: 0rem 1rem 2rem 1rem;
  gap: 1rem;


  section:first-of-type {
    align-items: flex-start;
    margin-top: 1.5rem;
  }

  section:not(:first-child) {
    padding-top: 4.9rem;
  }

  @media (min-width: 1920px) {
    max-width: 80%;
  }
}

section {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0rem;
  min-height: calc(100vh - 7rem);

  @media (min-width: 1920px) {
    max-width: 80%;
    min-height: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  img,
  p,
  button,
  div {
    margin: 0 0 0 0;
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 0 0;
  gap: 1rem;
  width: 100%;

  &-left-align {
    @extend .section-container;
    align-items: flex-start;
    gap: 0rem;
  }
}

.inline-link {
  color: $accent-color;
}

#about-me {
  flex-direction: row;
  gap: 1.25rem;

  .about-me-left {
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (max-width: 48rem) {
    flex-direction: column;
  }

  p {
    @media (max-width: 48rem) {}
  }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4rem;
  background-color: $nav-color;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: small;
  gap: 0.5rem;
  padding: 1rem 0rem 0rem 0rem;
}

.about-me-image {
  max-width: 20rem;
  border-radius: 100%;
  box-shadow: 0 0 0.75rem $accent-color;
  transition: 0.4s ease-in-out;
  margin: 0 0 1rem 0;
  // align-self: center;

  &:hover {
    box-shadow: 0 0 2rem $accent-color;
  }

  @media (max-width: 30rem) {
    max-width: 90%;
  }
}

@keyframes typing {

  1.2347%,
  2.4691% {
    content: "W";
  }

  2.4692%,
  3.7037% {
    content: "We";
  }

  3.7038%,
  4.9383% {
    content: "Web";
  }

  4.9384%,
  6.1728% {
    content: "Web ";
  }

  6.1729%,
  7.4074% {
    content: "Web D";
  }

  7.4075%,
  8.6420% {
    content: "Web De";
  }

  8.6421%,
  9.8765% {
    content: "Web Dev";
  }

  9.8766%,
  11.1111% {
    content: "Web Deve";
  }

  11.1112%,
  12.3457% {
    content: "Web Devel";
  }

  12.3458%,
  13.5802% {
    content: "Web Develo";
  }

  13.5803%,
  14.8148% {
    content: "Web Develop";
  }

  14.8149%,
  16.0494% {
    content: "Web Develope";
  }

  16.0495%,
  17.2840% {
    content: "Web Developer";
  }

  17.2841%,
  18.5185% {
    content: "Web Develope";
  }

  18.5186%,
  19.7531% {
    content: "Web Develop";
  }

  19.7532%,
  20.9877% {
    content: "Web Develo";
  }

  20.9878%,
  22.2222% {
    content: "Web Devel";
  }

  22.2223%,
  23.4568% {
    content: "Web Deve";
  }

  23.4569%,
  24.6914% {
    content: "Web Dev";
  }

  24.6915%,
  25.9259% {
    content: "Web De";
  }

  25.9260%,
  27.1605% {
    content: "Web D";
  }

  27.1606%,
  28.3951% {
    content: "Web ";
  }

  28.3952%,
  29.6296% {
    content: "Web";
  }

  29.6297%,
  30.8642% {
    content: "We";
  }

  30.8643%,
  32.0988% {
    content: "W";
  }

  32.0989%,
  33.3333% {
    content: "";
  }

  33.3334%,
  34.5679% {
    content: "S";
  }

  34.5680%,
  35.8025% {
    content: "So";
  }

  35.8026%,
  37.0370% {
    content: "Sof";
  }

  37.0371%,
  38.2716% {
    content: "Soft";
  }

  38.2717%,
  39.5062% {
    content: "Softw";
  }

  39.5063%,
  40.7407% {
    content: "Softwa";
  }

  40.7408%,
  41.9753% {
    content: "Softwar";
  }

  41.9754%,
  43.2099% {
    content: "Software";
  }

  43.2100%,
  44.4444% {
    content: "Software ";
  }

  44.4445%,
  45.6790% {
    content: "Software E";
  }

  45.6791%,
  46.9136% {
    content: "Software En";
  }

  46.9137%,
  48.1481% {
    content: "Software Eng";
  }

  48.1482%,
  49.3827% {
    content: "Software Engi";
  }

  49.3828%,
  50.6173% {
    content: "Software Engin";
  }

  50.6174%,
  51.8519% {
    content: "Software Engine";
  }

  51.8520%,
  53.0864% {
    content: "Software Enginee";
  }

  53.0865%,
  54.3210% {
    content: "Software Engineer";
  }

  54.3211%,
  55.5556% {
    content: "Software Enginee";
  }

  55.5557%,
  56.7901% {
    content: "Software Engine";
  }

  56.7902%,
  58.0247% {
    content: "Software Engin";
  }

  58.0248%,
  59.2593% {
    content: "Software Engi";
  }

  59.2594%,
  60.4938% {
    content: "Software Eng";
  }

  60.4939%,
  61.7284% {
    content: "Software En";
  }

  61.7285%,
  62.9630% {
    content: "Software E";
  }

  62.9631%,
  64.1975% {
    content: "Software ";
  }

  64.1976%,
  65.4321% {
    content: "Software";
  }

  65.4322%,
  66.6667% {
    content: "Softwar";
  }

  66.6668%,
  67.9012% {
    content: "Softwa";
  }

  67.9013%,
  69.1358% {
    content: "Softw";
  }

  69.1359%,
  70.3704% {
    content: "Soft";
  }

  70.3705%,
  71.6049% {
    content: "Sof";
  }

  71.6050%,
  72.8395% {
    content: "So";
  }

  72.8396%,
  74.0741% {
    content: "S";
  }

  74.0742%,
  75.3086% {
    content: "";
  }

  75.3087%,
  76.5432% {
    content: "P";
  }

  76.5433%,
  77.7778% {
    content: "Pr";
  }

  77.7779%,
  79.0123% {
    content: "Pro";
  }

  79.0124%,
  80.2469% {
    content: "Prog";
  }

  80.2470%,
  81.4815% {
    content: "Progr";
  }

  81.4816%,
  82.7160% {
    content: "Progra";
  }

  82.7161%,
  83.9506% {
    content: "Program";
  }

  83.9507%,
  85.1852% {
    content: "Programm";
  }

  85.1853%,
  86.4198% {
    content: "Programme";
  }

  86.4199%,
  87.6543% {
    content: "Programmer";
  }

  87.6544%,
  88.8889% {
    content: "Programme";
  }

  88.8890%,
  90.1235% {
    content: "Programm";
  }

  90.1236%,
  91.3580% {
    content: "Program";
  }

  91.3581%,
  92.5926% {
    content: "Progra";
  }

  92.5927%,
  93.8272% {
    content: "Progr";
  }

  93.8273%,
  95.0617% {
    content: "Prog";
  }

  95.0618%,
  96.2963% {
    content: "Pro";
  }

  96.2964%,
  97.5309% {
    content: "Pr";
  }

  97.5310%,
  98.7654% {
    content: "P";
  }

  98.7655%,
  100.0000% {
    content: "";
  }
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.typewriter {
  --caret: currentcolor;
  color: $accent-color;
}

.typewriter::before {
  content: "";
  animation: typing 13.5s infinite;
}

.typewriter::after {
  content: "";
  border-right: 1px solid var(--caret);
  animation: blink 0.5s linear infinite;
}

.typewriter.thick::after {
  border-right: 1ch solid var(--caret);
}

.typewriter.nocaret::after {
  border-right: 0;
}

@media (prefers-reduced-motion) {
  .typewriter::after {
    animation: none;
  }

  .typewriter::before {
    content: "developer";
    animation: sequencePopup 12s linear infinite;
  }
}

.icons-container {
  display: flex;
  gap: 1rem;
}

.icon-class {
  border: 2px solid $accent-color;
  border-radius: 100%;
  padding: 0.35rem;
  color: $accent-color;
  transition: 0.4s ease-in-out;
  max-width: 90%;

  &:hover {
    background-color: #0ffff8;
    box-shadow: 0 0 1.2rem $accent-color;
    color: white;
    scale: 1.25;
  }
}

.contentCards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1rem;
}

#contact {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  text-align: left;
  min-height: calc(100vh - 20rem);

  .contact-me-form {
    @extend #contact;
    margin: auto;
    width: 40rem;
    max-width: 90%;
    padding: 0.25rem;

    button {
      align-self: center;
    }
  }
}

.submit-button {
  svg {
    margin: 0 0 0 0.5rem;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}